import React from "react"
import Layout from "@components/layout"
import { WhatsNew } from "@components/pages/whats-new"

const MicrositeWhatsNew = () => {
  return (
    <Layout
      title={`What's New - PowerMetrics' newest releases`}
      description={`Keep up to date with new PowerMetrics features and product improvements.`}
      fullWidth
      marginless
      microsite
    >
      <WhatsNew microsite />
    </Layout>
  )
}

export default MicrositeWhatsNew
